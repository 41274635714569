<template>
  <!-- Minify class name: schedule-event-create-multiselect —> secm -->
  <div class="schedule-event-create-multiselect secm">
    <StylizedMultiselect v-bind="config" ref="multiselect" />
  </div>
</template>

<script>
import StylizedMultiselect from '@component/StylizedMultiselect';
export default {
  inheritAttrs: false,
  components: {
    StylizedMultiselect,
  },
  // props: {
  //   modelValue: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  computed: {
    config() {
      return {
        trackBy: 'combinedField',
        label: 'name',
        valueProp: 'id',
        // delay: 0,
        filterResults: true,
        //  options: this.enhancedOptionsTo,
        appendNewOption: false,
        createOption: false,
        object: true,
        ...this.$attrs,
      };
    },
  },
  methods: {
    // savePreviouslySelectedContact() {},
    // TODO: Асинхронное получение списка
    // async getOptionsToContactSearch(query) {
    //   const enhancedOptionsTo = (options) => {
    //     return options.map((option) => ({
    //       ...option,
    //       combinedField: option.name + option.email,
    //     }));
    //   };
    //   const response = await fetch(
    //     "https://jsonplaceholder.typicode.com/users"
    //   );
    //   let options = await response.json();
    //   options = enhancedOptionsTo(options).filter(({ combinedField }) =>
    //     combinedField.includes(query)
    //   );
    //   // TODO: Fix, return simple options
    //   options = [...Object.values(this.modelValue), ...options];
    //   return options;
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.stylized-multiselect) {
  --ms-bg: transparent;
  --ms-border-width: 1px;
  --ms-border-color: #babace;
  --ms-radius: 8px;
}
</style>
