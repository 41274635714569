<template>
  <div class="dropdown__list fs__filter__list">
    <template v-for="item in weekDayList" :key="item.key">
      <div class="fs__filter__item">
        <FveCheckbox
            :field="{
            name: item.key,
            model: true,
          }"
            @update:modelValue="selectWeekDay(item, $event)"
            :label="item.name"
            :key="item.key"
        />
      </div>
    </template>
  </div>
</template>
<script>
import FveCheckbox from "@fve/Switch/FveCheckbox";

export default {
  name: 'ScheduleWeekdayPicker',
  components: {
    FveCheckbox,
  },
  setup() {
    return {
    };
  },
  data() {
    return {
      // prettier-ignore
      weekDayList: [
        { name: "Sunday",     key: "Sunday",    value: "Sunday",  },
        { name: "Monday",     key: "Monday",    value: "Monday",  },
        { name: "Tuesday",    key: "Tuesday",   value: "Tuesday",  },
        { name: "Wednesday",  key: "Wednesday", value: "Wednesday",  },
        { name: "Thursday",   key: "Thursday",  value: "Thursday",  },
        { name: "Friday",     key: "Friday",    value: "Friday",  },
        { name: "Saturday",   key: "Saturday",  value: "Saturday",  },
      ],
    };
  },
  methods: {
    selectWeekDay({ value }, $event) {
      this.$emit('selectWeekDay', value);
    },
  },
};
</script>
