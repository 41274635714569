<template>
  <!-- Minify class name schedule-event-permission-dropdown —> sepd -->
  <PopperDropdown placement="bottom-start">
    <slot></slot>
    <template #popper>
      <div class="schedule-event-permission-dropdown sepd">
        <p class="sepd__title">Guest permissions</p>
        <div class="sepd__checklist dropdown__list">
          <template v-for="item in checklist" :key="item.key">
            <FveCheckbox
              :field="{ name: item.key, model: true }"
              :label="item.name"
              @update:modelValue="changePermissionState(item.key, $event)"
              :modelValue="!!modelValue[item.key]"
            />
          </template>
        </div>
      </div>
    </template>
  </PopperDropdown>
</template>

<script>
import FveCheckbox from "@fve/Switch/FveCheckbox.vue";
import PopperDropdown from "@component/Popper/PopperDropdown";

export default {
  components: {
    PopperDropdown,
    FveCheckbox,
  },
  props: {
    modelValue: {
      type: Object,
      defaut: () => ({}),
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      checklist: [
        {
          name: "Modify event",
          key: "modifyEvent",
        },
        {
          name: "Invite others",
          key: "inviteOthers",
        },
        {
          name: "See guest list",
          key: "seeGuestList",
        },
      ],
    };
  },
  methods: {
    changePermissionState(key, value) {
      const obj = { ...this.modelValue };
      if (!value && obj?.[key]) delete obj[key];
      else obj[key] = value;
      this.$emit("update:modelValue", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.sepd {
  min-width: 12rem;
}
.sepd__title {
  color: var(--color-gray-05);
  font-size: 0.875rem;
  padding: 1rem 1rem 0;
}
.serd__item {
  font-size: 0.875rem;
}
</style>
