<template>
  <PopperDropdown placement="bottom-start">
    <slot :text="list[modelValue]"></slot>
    <template #popper="{ hide }">
      <div class="schedule-event-repeat-dropdown serd__list dropdown__list">
        <template v-for="(item, key) in list" :key="key">
          <div class="serd__item" @click="select(key), hide()">{{ item }}</div>
        </template>
      </div>
    </template>
  </PopperDropdown>
</template>

<script>
import PopperDropdown from "@component/Popper/PopperDropdown";

export default {
  components: {
    PopperDropdown,
  },
  props: {
    date: { type: String, default: "" },
    modelValue: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      list: {
        1: "Does not repeat",
        2: "Daily",
        3: "Weakly on Wednesday",
        4: "Mothly on the first Wednesday",
        5: "Annually on",
        6: "Every weekday (Monday to Friday)",
      },
    };
  },
  methods: {
    select(key) {
      this.$emit("update:modelValue", +key);
    },
  },
};
</script>

<style lang="scss" scoped>
.serd__item {
  font-size: 0.875rem;
}
</style>
