<template>
  <!-- Minify class name: date-time-picker —> dtp -->
  <PopperDropdown :placement="'top-start'" :distance="10">
    <slot :date="modelValue"></slot>
    <!-- :firstDayOfWeek="2" -->
    <template #popper>
      <DatePicker
        mode="date"
        :masks="{ weekdays: 'WWW' }"
        :min-date="new Date()"
        :attributes="[{ key: 'today', dot: false, dates: new Date() }]"
        v-model="date"
        locale="en"
        class="dtp__datepicker"
        timezone=""
      ></DatePicker>
      <div :class="['dtp__time', { 'dtp__time--skinny': isTimeRange }]">
        <!-- prettier-ignore  -->
        <input :value="hours1" @input="hours1 = setPartTimeValue($event, 'hour')" type="text" placeholder="00" />
        :
        <!-- prettier-ignore  -->
        <input :value="minutes1" @input="minutes1 = setPartTimeValue($event, 'minute')"  type="text" placeholder="00" />
        <template v-if="isTimeRange">
          —
          <!-- prettier-ignore  -->
          <input :value="hours2" @input="hours2 = setPartTimeValue($event, 'hour')" type="text" placeholder="00" />
          :
          <!-- prettier-ignore  -->
          <input :value="minutes2" @input="minutes2 = setPartTimeValue($event, 'minute')"  type="text" placeholder="00" />
        </template>
      </div>
    </template>
  </PopperDropdown>
</template>

<script>
import { debounce } from "lodash";
import PopperDropdown from "@component/Popper/PopperDropdown";
import { DatePicker } from "v-calendar";

export default {
  name: "DateTimePicker",
  components: {
    PopperDropdown,
    DatePicker,
  },
  props: {
    isTimeRange: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "MM/DD/YY hh:mm A",
    },
    isVisibleWeekday: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {
      date: "",
      // Start (and used without range)
      hours1: "",
      minutes1: "",
      // End
      hours2: "",
      minutes2: "",
    };
  },
  watch: {
    prepareDate: {
      handler: function (date) {
        this.$emit("update:modelValue", date);
      },
      deep: true,
    },
    modelValue(current, previous) {
      if (current && current !== previous) {
        this.parseDate();
      }
    },
  },
  computed: {
    prepareDate() {
      if (this.isTimeRange) {
        const { date, hours1, hours2, minutes1, minutes2 } = this;
        const start = this.onPrepareDate(date, hours1, minutes1);
        const end = this.onPrepareDate(date, hours2, minutes2);
        return start && end
          ? {
              start,
              end,
            }
          : "";
      }
      return this.onPrepareDate(this.date, this.hours1, this.minutes1);
    },
  },
  mounted() {
    this.parseDate();
  },
  methods: {
    setPartTimeValue($event, unit) {
      let value = parseInt($event.target.value);

      // Получаем две последних цифры числа для вывода в поле
      value = Number(value.toString().slice(-2));

      if (isNaN(value)) {
        $event.target.value = "";
        return "";
      }

      if (unit === "hour") {
        value = value < 23 ? value : 23;
      }
      if (unit === "minute") {
        value = value < 59 ? value : 59;
      }

      // if (value) {
      value = value.toString().padStart(2, "0");
      // }

      $event.target.value = value;
      return value;
    },
    onPrepareDate(date, hours, minutes) {
      date = DateTime(date);

      // Сброс времени после выбора даты через Datepicker
      date = date.set("hour", 0).set("minute", 0).set("second", 0);

      if (hours) date = date.set("hour", parseInt(hours));
      if (minutes) date = date.set("minute", parseInt(minutes));

      return date.isValid() ? date.utc().format() : "";
    },
    parseDate() {
      // return;
      if (!this.modelValue) return;

      const partTimeToString = (date, partTime) => {
        date = DateTime(date);
        return date.isValid()
          ? String(date.get(partTime)).padStart(2, "0")
          : "";
      };

      if (this.isTimeRange) {
        const { start, end } = this.modelValue;
        this.date = start;

        this.hours1 = partTimeToString(start, "hour");
        this.minutes1 = partTimeToString(start, "minute");

        this.hours2 = partTimeToString(end, "hour");
        this.minutes2 = partTimeToString(end, "minute");
      } else {
        this.date = this.modelValue;
        this.hours1 = partTimeToString(this.date, "hour");
        this.minutes1 = partTimeToString(this.date, "minute");
      }
    },
    resetDate() {
      this.date = "";
      this.hours1 = "";
      this.hours2 = "";
      this.minutes1 = "";
      this.minutes2 = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.dtp__link {
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    @include icon-size(1.5);
    fill: var(--color-secondary-light);
  }
}
.dtp__link a {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0 8px;
  line-height: 150%;
  text-decoration: underline;
  color: var(--color-secondary-light);
}

.dtp__datepicker {
  --rounded-full: 8px;
  --blue-600: var(--color-secondary);
  --gray-900: var(--color-gray-06);
  background-color: var(--schedule-datepicker-background);
  // box-shadow: 6px 20px 36px rgba(6, 18, 55, 0.04);
  border: 0;
  ::v-deep(.vc-title) {
    font-size: 0.875rem;
    font-weight: normal;
    font-family: var(--font-family);
    color: var(--schedule-datepicker-title-color);
  }
  ::v-deep(.vc-highlight) {
    color: #fff !important;
  }
  ::v-deep(.vc-weekday) {
    color: var(--color-gray-06);
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.625rem;
  }
  ::v-deep(.vc-day-content) {
    font-size: 0.875rem;
  }
}

.dtp__time {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 8px;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  color: var(--color-secondary-light);
  background-color: var(--schedule-datepicker-background);
  border-top: 1px solid var(--color-gray-02);
  input {
    width: 54px;
    height: 34px;
    border: 1px solid var(--color-secondary-light);
    border-radius: 8px;
    text-align: center;
    &::placeholder {
      color: var(--color-secondary-light);
    }
  }
  &--skinny {
    input {
      width: 38px;
    }
  }
}

.vc-container {
  background-color: var(--schedule-datepicker-background) !important;
}

</style>
