<template>
  <FveForm>
    <div class="schedule-event-create sec">
      <div class="sec__field-wrapper">
        <div :class="['sec__dot', `sec__dot--${form.type}`]"></div>
        <FveText
          :field="field.title"
          placeholder="Add title"
          class="sec__field"
        />
      </div>

      <div class="sec__tab__menu">
        <FveSelectButtonValue
          :field="field.type"
          :option-list="[
            { id: 'meeting', name: 'Meeting' },
            { id: 'task', name: 'Task' },
            { id: 'reminder', name: 'Reminder' },
          ]"
          placeholder="Add title"
        />
      </div>
      <div class="sec__tab__content">
        <div class="sec__setting__list">
          <!-- Выбор даты -->
          <div class="sec__setting__item">
            <!-- prettier-ignore -->
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sec__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.445.832l3 2a1 1 0 001.11-1.664L13 11.465V8z"/></svg>
            <div class="sec__setting__inner">
              <template v-if="form.type === $enum.SCHEDULE_EVENT_TYPE.TASK">
                <div class="sec__setting__above">Deadline</div>
              </template>
              <div class="sec__setting__actions">
                <div class="sec__setting__action">
                  <template v-if="form.repeat === 1">
                    <FveDateTimePickerPeriodViewText
                      v-if="form.type === $enum.SCHEDULE_EVENT_TYPE.MEETING"
                      :field="field.datePeriod"
                    />
                    <FveDateTimePickerViewText v-else :field="field.dateItem" />
                  </template>

                  <template v-else-if="form.repeat === 2">
                    <FveDateRangePicker :field="field.period" />
                  </template>

                  <template v-else-if="form.repeat === 3">
                    <div class="extended-date-wrapper">
                      <FveDateRangePicker :field="field.period" />
                      <div style="margin-left: 10px">
                        <PopperDropdown>
                          <div style="display: flex">
                            <template v-if="weekDays.length">
                              <div
                                v-for="day in weekDays"
                                :key="day"
                                style="text-transform: capitalize"
                              >
                                {{ day.toLowerCase() }}&nbsp;
                              </div>
                            </template>
                            <template v-else> Select weekdays </template>
                          </div>
                          <template #popper="{ hide }">
                            <ScheduleWeekdayPicker
                              @selectWeekDay="setDay($event), hide()"
                            />
                          </template>
                        </PopperDropdown>
                      </div>
                    </div>
                  </template>

                  <template
                    v-else-if="
                      form.repeat === 3 ||
                      form.repeat === 4 ||
                      form.repeat === 5
                    "
                  >
                    <div class="extended-date-wrapper">
                      <FveDateRangePicker :field="field.period" />
                      <div class="extended-date-day">
                        <span>Day:</span>
                        <Multiselect
                          :options="daysListDummy"
                          v-model="dayOfMonth"
                          :can-clear="false"
                          class="multiselect-customize"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="sec__setting__under">
                <div class="sec__setting__action">Local time</div>
                •
                <FveSelectPopperDropdownValue
                  :field="field.repeat"
                  :option-list="REPEAT_OPTIONS"
                />
              </div>
            </div>
          </div>

          <template
            v-if="
              form.type === $enum.SCHEDULE_EVENT_TYPE.MEETING ||
              form.type === $enum.SCHEDULE_EVENT_TYPE.TASK
            "
          >
            <div class="sec__setting__item sec__setting__item--contact">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sec__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 11a3 3 0 100-6 3 3 0 000 6zM9.385 16.71c-.155.448-.17.887-.078 1.29H4c-1.105 0-2.031-.92-1.67-1.963a6.002 6.002 0 019.498-2.658 7.006 7.006 0 00-2.443 3.33zM19 9a3 3 0 11-6 0 3 3 0 016 0zM20 19c1.105 0 2.031-.92 1.67-1.963a6.002 6.002 0 00-7.966-3.58 6 6 0 00-3.374 3.58C9.97 18.08 10.896 19 12 19h8z"/></svg>
              <div class="sec__setting__inner">
                <!-- {{ to }} -->
                <div
                  v-if="!isVisibleContactSearch"
                  class="sec__setting__actions"
                >
                  <span
                    @click="isVisibleContactSearch = true"
                    class="sec__setting__action"
                  >
                    <template
                      v-if="
                        !form.to.length &&
                        form.type === $enum.SCHEDULE_EVENT_TYPE.MEETING
                      "
                    >
                      Add guests
                    </template>
                    <template
                      v-else-if="
                        !form.assignee.length &&
                        form.type === $enum.SCHEDULE_EVENT_TYPE.TASK
                      "
                      >Unassigned</template
                    >
                    <template
                      v-else-if="
                        form.type === $enum.SCHEDULE_EVENT_TYPE.TASK &&
                        form.assignee.length
                      "
                      >{{ form.assignee.length }} assigned</template
                    >
                    <template v-else-if="form.to.length"
                      >{{ form.to.length }} members</template
                    >
                  </span>
                  <!--<template v-if="form.type === $enum.SCHEDULE_EVENT_TYPE.MEETING">-->
                  <!--  <ScheduleEventPermissionDropdown v-model="form.permissions">-->
                  <!--    <span class="sec__setting__action">Permissions</span>-->
                  <!--  </ScheduleEventPermissionDropdown>-->
                  <!--</template>-->
                  <!--<template v-if="form.type === $enum.SCHEDULE_EVENT_TYPE.TASK">-->
                  <!--  <span class="sec__setting__action">Assign to me</span>-->
                  <!--</template>-->
                </div>
                <!-- <div v-show="isVisibleContactSearch"> -->
                <template v-if="isVisibleContactSearch">
                  <template v-if="form.type === $enum.SCHEDULE_EVENT_TYPE.TASK">
                    <ScheduleEventCreateMultiselect
                      ref="multiselect"
                      v-model="selectedMemberPrepare"
                      :single="true"
                      :options="enhancedOptionsTo"
                      @close="isVisibleContactSearch = false"
                    />
                  </template>

                  <template v-else>
                    <ScheduleEventCreateMultiselect
                      ref="multiselect"
                      :options="enhancedOptionsTo"
                      v-model="selectedMemberPrepare"
                      @close="isVisibleContactSearch = false"
                    />
                  </template>
                </template>

                <!-- </div> -->
              </div>
            </div>
            <!-- Добавление комментария -->
            <div class="sec__setting__item sec__setting__item--description">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sec__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 12c0-2.809 0-4.213.674-5.222a4 4 0 011.104-1.104C6.787 5 8.19 5 11 5h2c2.809 0 4.213 0 5.222.674.437.292.812.667 1.104 1.104C20 7.787 20 9.19 20 12c0 2.809 0 4.213-.674 5.222a4.003 4.003 0 01-1.104 1.104c-.563.377-1.25.543-2.237.616L14.5 21l-1.443-2H11c-2.809 0-4.213 0-5.222-.674a4.002 4.002 0 01-1.104-1.104C4 16.213 4 14.81 4 12zm13 0a1 1 0 10-2 0 1 1 0 002 0zm-4 0a1 1 0 10-2 0 1 1 0 002 0zm-5-1a1 1 0 110 2 1 1 0 010-2z"/></svg>
              <div class="sec__setting__inner">
                <div class="sec__setting__actions">
                  <span
                    @click="isVisibleDescription = !isVisibleDescription"
                    class="sec__setting__action"
                  >
                    Add description
                  </span>
                </div>
                <template v-if="isVisibleDescription">
                  <FveTextarea
                    class="sec__setting__textarea"
                    :field="field.description"
                  />
                </template>
              </div>
            </div>
            <div class="sec__setting__item sec__setting__item--description">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click.prevent="fileStorageShown = true" v-bind:class="'sec__setting__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>

              <Dropzone
                class="sec__setting__inner"
                @onDrop="selectFileList"
                v-slot="{ hide, show }"
              >
                <div class="sec__setting__actions">
                  <span
                    @click="$refs.inputFile.click()"
                    class="sec__setting__action"
                    >Add attachment</span
                  >
                </div>
                <input
                  id="input-file"
                  type="file"
                  @change="(event) => selectFileList(event.target.files)"
                  ref="inputFile"
                  hidden
                />

                <div class="sec__attachment-list">
                  <DropzoneDraggable
                    :items="prepareCommonFileList"
                    @update:items="onUpdateItems"
                    @drag-start="show"
                    @drag-end="hide"
                    orientation="vertical"
                  >
                    <template #item="{ item, index }">
                      <Tag
                        class="sec__attachment-item"
                        :key="index"
                        :label="item.filename"
                        :canClose="true"
                        @close="removeFileListItem(item)"
                      />
                    </template>
                  </DropzoneDraggable>
                </div>
              </Dropzone>
            </div>
          </template>
        </div>
      </div>
      <div class="sec__btn-group">
        <button type="button" @click="$emit('close')" class="btn btn--outline">
          Discard
        </button>
        <button type="button" @click="submit" class="btn btn--primary">
          Save
        </button>
      </div>
    </div>
  </FveForm>
</template>

<script>
//
import FveMixinForm from '@fve/Mixin/FveMixinForm';
//
import FveText from '@fve/Element/Text/FveText';
import FveTextarea from '@fve/Element/Text/FveTextarea';
import FveSelectButtonValue from '@fve/Element/Select/FveSelectButtonValue';
import FveSelectPopperDropdownValue from '@fve/Element/Select/FveSelectPopperDropdownValue';
import FveDateTimePickerPeriodViewText from '@fve/Element/Date/FveDateTimePickerPeriodViewText';
import FveDateTimePickerViewText from '@fve/Element/Date/FveDateTimePickerViewText';
//
import ScheduleEventRepeatDropdown from '@component/Schedule/ScheduleEventRepeatDropdown';
import ScheduleEventCreateMultiselect from '@component/Schedule/ScheduleEventCreateMultiselect';
import DateTimePicker from '@component/DateTimePicker';
import ScheduleEventPermissionDropdown from '@component/Schedule/ScheduleEventPermissionDropdown';
import { communityGetList, communityGetSelected } from '@store/community.store';
import FveDatePicker from '@fve/Element/Date/_FveDatePickerOld';
import FveDateRangePicker from '@fve/Element/Date/FveDateRangePicker';
import $enum from '@enum';

import ScheduleWeekdayPicker from '@component/Schedule/ScheduleWeekdayPicker';

import Multiselect from '@vueform/multiselect';
import PopperDropdown from '@component/Popper/PopperDropdown';
import {
  fileStorageAddToList,
  fileStorageShown,
} from '@store/fileStorage.store';
import Tag from '@component/Tag';

import Dropzone from '@component/Dropzone';
import DropzoneDraggable from '@component/DropzoneDraggable';

const communityList = communityGetList();

const REPEAT_OPTIONS = [
  { id: 1, name: 'Does not repeat' },
  { id: 2, name: 'Daily' },
  { id: 3, name: 'Weakly on' },
  { id: 4, name: 'Mothly on' },
  { id: 5, name: 'Every year on' },
];

export default {
  components: {
    FveDateRangePicker,
    FveDateTimePickerPeriodViewText,
    FveDateTimePickerViewText,
    FveSelectPopperDropdownValue,
    FveSelectButtonValue,
    FveText,
    FveTextarea,
    ScheduleEventCreateMultiselect,
    Multiselect,
    ScheduleWeekdayPicker,
    PopperDropdown,
    Tag,
    Dropzone,
    DropzoneDraggable,
  },
  mixins: [FveMixinForm],
  data() {
    return {
      fileStorageShown,
      REPEAT_OPTIONS,
      communitySelected: communityGetSelected(),
      isVisibleContactSearch: false,
      isVisibleDescription: false,
      isVisibleAttachment: false,
      // TODO: Исправить на form-поля
      dayOfMonth: 1,
      dayOfYear: '01/01',
      weekDays: [],
      filesList: [],
    };
  },
  watch: {
    isVisibleContactSearch() {},
  },
  computed: {
    prepareCommonFileList: {
      get() {
        // source
        // index

        const downloadable = this.filesList.map(({ name }, index) => {
          return {
            filename: name,
            url: '',
            backetname: '',
            source: 'downloadable',
            index,
          };
        });

        const downloaded = this.form.files.map((item, index) => {
          return {
            ...item,
            source: 'downloaded',
            index,
          };
        });

        return [...downloadable, ...downloaded];
      },
      set() {},
    },
    // Перебираем и приводим модели участников, к той что ожидаем в нашем селекте
    selectedMemberPrepare: {
      set(v) {
        if (this.form.type === this.$enum.SCHEDULE_EVENT_TYPE.TASK) {
          this.form.assignee = this.communitySelected?.participants?.filter(
            (item) => v.find(({ id }) => id === item.id)
          );
        } else {
          this.form.to = this.communitySelected?.participants?.filter((item) =>
            v.find(({ id }) => id === item.id)
          );
        }
      },
      get() {
        const options =
          this.form.type === this.$enum.SCHEDULE_EVENT_TYPE.TASK
            ? this.form.assignee
            : this.form.to;
        return options
          .map(({ fullname, mail, id, profilePhoto }) => {
            return {
              id,
              name: fullname,
              profilePhoto: profilePhoto,
              combinedField: fullname !== mail ? fullname + mail : mail,
            };
          })
          .filter(({ id }) => id !== USER.Auth.getUserId());
      },
    },
    enhancedOptionsTo() {
      const options = this.communitySelected?.participants || [];
      return options.map(({ fullname, mail, id, profilePhoto }) => {
        return {
          id,
          name: fullname,
          profilePhoto: profilePhoto,
          combinedField: fullname !== mail ? fullname + mail : mail,
        };
      });
    },

    daysListDummy() {
      let list = [];
      let counter = 1;
      while (counter <= 31) {
        list.push(counter);
        counter++;
      }
      return list;
    },
  },
  mounted() {
    // console.log('scheduleEventCreate: ', this.formData);
  },
  methods: {
    // prettier-ignore
    formSchema() {
      return {
        // id:          { type: String,   default: () => '' },
        to:          { type: Array,    default: () => [] },
        assignee:    { type: Array,    default: () => [] },
        title:       { type: String,   default: () => { return ''; }, field: { required: true, } },
        permissions: { type: Object,   default: () => ({}) },
        description: { type: String,   default: () => '' },
        repeat:      { type: Number,   default: () => 1 },
        files:       { type: Array,    default: () => [] },
        type:        { type: String,   default: () => this.$enum.SCHEDULE_EVENT_TYPE.MEETING },
        //
        dateItem:    { type: DateTime, default: () => null, field: { required: true } }, // DateTime,
        datePeriod:  { type: Object,   default: () => ({}), field: { required: true } }, // { start: DateTime, end: DateTime, },
        period:      { type: Object,   default: () => ({}), field: { required: true } },
      };
    },

    setDay(day) {
      day = day.toUpperCase();
      if (this.weekDays.includes(day)) {
        const index = this.weekDays.indexOf(day);
        if (index > -1) {
          this.weekDays.splice(index, 1);
        }
      } else {
        this.weekDays.push(day);
      }
    },

    onUpdateItems(items) {
      this.form.files = this.form.files.concat(
        items.filter((item) => !item?.source)
      );
    },

    removeFileListItem({ source, index }) {
      if (source === 'downloadable') {
        this.filesList.splice(index, 1);
      } else if (source === 'downloaded') {
        this.form.files.splice(index, 1);
      }
    },

    selectFileList(files) {
      const fileList = Array.from(files);

      this.filesList = this.filesList.concat(fileList);
      console.log(this.filesList);
    },

    selectCommunity(communityId) {
      let community = null;
      if (communityId) {
        community = communityList.value.find((item) => item.id === communityId);
      }
      return community;
    },

    submitSuccessDataPrepare(submitData) {
      if (submitData.period && submitData.repeat > 1) {
        if (submitData.type === $enum.SCHEDULE_EVENT_TYPE.MEETING) {
          submitData.datePeriod = {
            start: null,
            end: null,
          };
          submitData.datePeriod.start = submitData.period.startDate;
          submitData.datePeriod.end = submitData.period.endDate;
        } else {
          submitData.dateItem = submitData.period.startDate;
        }
      }

      // Добавление в объект полей не входящих в состав формы
      const submitDataPrepare = {
        ...submitData,
        to: this.form.to,
        assignee: this.form.assignee,
        permissions: this.form.permissions,
        description: this.form.description,
        community:
          this.formData && this.formData.community
            ? this.selectCommunity(this.formData.community.id)
            : this.communitySelected,
      };

      if (submitData.period) {
        submitDataPrepare.payloadObj = {};
        submitDataPrepare.payloadObj = {
          dayOfMonth: this.dayOfMonth,
          dayOfYear: `${this.dayOfMonth}/${submitData.period.startDate.format(
            'MM'
          )}`,
        };

        if (this.weekDays.length) {
          submitDataPrepare.payloadObj.dayOfWeek = this.weekDays.join(',');
        }
      }

      if (this.filesList.length) {
        submitDataPrepare.fileList = this.filesList;
      }

      if (this.form.files.length) {
        submitDataPrepare.files = this.form.files;
      }

      return submitDataPrepare;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep() {
  .smooth-dnd-shown {
    margin-top: -1.6rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}
.sec__attachment-item {
  width: fit-content;
  margin: 0.5em 0;
}
.sec__field-wrapper {
  position: relative;
}
.sec__field {
  margin-left: 2.25rem;
  ::v-deep(.fve-control > input) {
    background-color: transparent;
    border: 1px solid var(--schedule-event-input-border-color);
  }
}
.sec__dot {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include icon-size(1.25);
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    display: block;
    @include icon-size(0.625);
    border-radius: 100%;
  }
  &--meeting::before {
    background-color: var(--color-secondary);
  }
  &--task::before {
    background-color: var(--color-cyan);
  }
  &--reminder::before {
    background-color: var(--color-yellow);
  }
}
.sec__tab__menu {
  display: flex;
  margin: 1.5rem -0.25rem 0.75rem 2rem;
  ::v-deep(.select-button-item--meeting.select-button-item--active) {
    background-color: transparentize($color-secondary, 0.84);
    color: var(--color-secondary);
  }
  ::v-deep(.select-button-item--task.select-button-item--active) {
    background-color: transparentize($color-cyan, 0.84); // #eafbfa;
    color: var(--color-cyan);
  }
  ::v-deep(.select-button-item--reminder.select-button-item--active) {
    background-color: transparentize($color-yellow, 0.84); // #fefae6;
    color: var(--color-yellow);
  }
}

.sec__btn-group {
  margin: 0.75rem -0.5rem 0;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0.5rem;
  }
}

.sec__setting__item {
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  padding: 0.75rem 0;
  &--contact {
    align-items: center;
  }
}

.sec__setting__inner {
  width: 100%;
}

.sec__setting__actions {
  margin: 0 -0.5rem;
  display: flex;
  .sec__setting__action {
    cursor: pointer;
    margin: 0 0.5rem;
  }
}
.sec__setting__under,
.sec__setting__above {
  color: var(--color-gray-05);
}
.sec__setting__under {
  display: flex;
  margin: 0 -0.25rem;
  .sec__setting__action {
    margin: 0 0.25rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
}

.sec__setting__icon,
.sec__setting__caret {
  fill: var(--color-secondary-light);
}
.sec__setting__icon {
  @include icon-size(1.25);
  margin-right: 1rem;
}

.sec__setting__caret {
  @include icon-size(1);
  margin-left: 0.1rem;
}

.sec__setting__textarea {
  margin: 0.25rem 0 0.5rem;
}

// TODO: Remove this styles after update

.extended-date-wrapper {
  display: flex;
  flex: 0 0 180px;
  align-items: center;
}

.extended-date-day {
  width: 150px;
  display: flex;
  align-items: center;

  & > span {
    display: inline-block;
    margin: 0 10px;
  }
}

.multiselect-customize {
  .theme-dark & {
    --ms-border-color: var(--schedule-event-input-border-color);
    --ms-dropdown-border-color: var(--schedule-event-input-border-color);
    --ms-bg: var(--color-gray-02);
    --ms-dropdown-bg: var(--color-gray-02);
    --ms-option-bg-selected: rgba(86, 107, 214, 0.05 ); // var(--color-secondary);
    --ms-option-bg-pointed: rgba(86, 107, 214, 0.15); // var(--color-secondary);
    --ms-option-color-selected: var(--color-secondary);
    --ms-option-color-pointed: var(--color-secondary);
    --ms-option-bg-selected-pointed: rgba(86, 107, 214, 0.2);
    --ms-option-color-selected-pointed: var(--color-secondary);
    --ms-ring-color: rgba(86, 107, 214, 0.15);
    // --ms-dropdown-bg: ;
  }
}
</style>
